var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "filter-group", class: _vm.getClass(_vm.level) },
        [
          _c(
            "div",
            { staticClass: "filter-header" },
            [
              _c("label", { staticClass: "mr-1" }, [
                _vm._v(_vm._s(_vm.$t("dataview.field.match_type"))),
              ]),
              _c("multiselect", {
                staticClass: "custom-dropdown-options enable-option-icon",
                attrs: {
                  "max-height": 300,
                  options: _vm.groupOptions.map((i) => i.value),
                  "custom-label": _vm.getGroupOptionLabel,
                  placeholder: "",
                  searchable: false,
                  "allow-empty": false,
                  showLabels: false,
                  disabled: _vm.disabled,
                },
                scopedSlots: _vm._u([
                  {
                    key: "option",
                    fn: function (props) {
                      return [
                        _vm.query.operator == props.option
                          ? _c("font-awesome-icon", {
                              staticClass: "selected-option-icon",
                              attrs: { icon: ["far", "check"] },
                            })
                          : _vm._e(),
                        _c("span", { staticClass: "option__title" }, [
                          _vm._v(_vm._s(_vm.getGroupOptionLabel(props.option))),
                        ]),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.query.operator,
                  callback: function ($$v) {
                    _vm.$set(_vm.query, "operator", $$v)
                  },
                  expression: "query.operator",
                },
              }),
              _c(
                "b-alert",
                {
                  staticClass: "filter-header-alert",
                  attrs: {
                    size: "sm",
                    show:
                      _vm.query.operator === "_not_" &&
                      _vm.query.children.length > 1,
                    variant: "danger",
                  },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fas", "triangle-exclamation"] },
                  }),
                  _vm._v("  " + _vm._s(_vm.$t("dataview.error.not")) + " "),
                ],
                1
              ),
              _vm.level > 0
                ? _c(
                    "button",
                    {
                      staticClass: "btn-remove",
                      on: {
                        click: function ($event) {
                          return _vm.removeGroup()
                        },
                      },
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "btn-icon-color",
                        attrs: { icon: ["far", "xmark"] },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-buttons" },
            [
              _c(
                "b-button",
                {
                  staticClass: "mr-2",
                  attrs: {
                    disabled:
                      _vm.disabled ||
                      (_vm.query.operator === "_not_" &&
                        _vm.query.children.length >= 1),
                    size: "sm",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.addRule(_vm.query.children)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("button.add_rule")))]
              ),
              _c(
                "b-button",
                {
                  attrs: {
                    disabled:
                      _vm.disabled ||
                      (_vm.query.operator === "_not_" &&
                        _vm.query.children.length >= 1),
                    size: "sm",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.addGroup(_vm.query.children)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("button.add_group")))]
              ),
            ],
            1
          ),
          _vm._l(_vm.query.children, function (item, itemIndex) {
            return _c(
              "div",
              { key: itemIndex, staticClass: "mt-2" },
              [
                item.type === "rule"
                  ? _c(
                      "div",
                      {
                        staticClass: "filter-rule",
                        class: _vm.getRuleClass(_vm.level),
                      },
                      [
                        _c("div", { staticClass: "item-field-container" }, [
                          _c(
                            "div",
                            {
                              staticClass: "clickable",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  !_vm.disabled && _vm.editField(item)
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "item-field-label" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.field !== null && item.field !== ""
                                        ? _vm.formatField(item)
                                        : _vm.$t("dataview.placeholder.field")
                                    ) +
                                    " "
                                ),
                              ]),
                              !_vm.disabled
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "edit-icon-container",
                                      attrs: {
                                        id: `EDIT_TYPE_${_vm.id}_${itemIndex}`,
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        staticClass: "edit-icon-color",
                                        attrs: {
                                          icon: ["far", "pen-to-square"],
                                        },
                                      }),
                                      _c("b-popover", {
                                        attrs: {
                                          target: `EDIT_TYPE_${_vm.id}_${itemIndex}`,
                                          placement: "top",
                                          triggers: "hover",
                                          content: _vm.$t("button.edit"),
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]),
                        _c(
                          "b-form-invalid-feedback",
                          {
                            staticClass: "alert-danger form-field-alert",
                            class: { "d-block": item.fieldError },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["far", "circle-exclamation"] },
                            }),
                            _vm._v(
                              "  " +
                                _vm._s(_vm.$t("dataview.error.field")) +
                                " "
                            ),
                          ],
                          1
                        ),
                        !_vm.disabled
                          ? _c(
                              "button",
                              {
                                staticClass: "btn-rule-remove",
                                on: {
                                  click: function ($event) {
                                    return _vm.removeItem(itemIndex)
                                  },
                                },
                              },
                              [
                                _c("font-awesome-icon", {
                                  staticClass: "btn-icon-color",
                                  attrs: { icon: ["far", "xmark"] },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "d-flex flex-wrap" },
                          [
                            _c(
                              "b-form-group",
                              { staticClass: "filter-from-group" },
                              [
                                _c("multiselect", {
                                  staticClass:
                                    "custom-dropdown-options enable-option-icon fit-content-fix fit-label-fix",
                                  attrs: {
                                    "max-height": 300,
                                    options: _vm
                                      .getOperators(item.field, item)
                                      .map((i) => i.value),
                                    "custom-label": _vm.getOperatorOptionLabel(
                                      item.field,
                                      item
                                    ),
                                    placeholder: "",
                                    searchable: false,
                                    "allow-empty": false,
                                    showLabels: false,
                                    disabled: _vm.disabled,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "option",
                                        fn: function (props) {
                                          return [
                                            item.operator == props.option
                                              ? _c("font-awesome-icon", {
                                                  staticClass:
                                                    "selected-option-icon",
                                                  attrs: {
                                                    icon: ["far", "check"],
                                                  },
                                                })
                                              : _vm._e(),
                                            _c(
                                              "span",
                                              { staticClass: "option__title" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getOperatorOptionLabel(
                                                      item.field,
                                                      item
                                                    )(props.option)
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: item.operator,
                                    callback: function ($$v) {
                                      _vm.$set(item, "operator", $$v)
                                    },
                                    expression: "item.operator",
                                  },
                                }),
                                _c(
                                  "b-form-invalid-feedback",
                                  {
                                    staticClass:
                                      "alert-danger form-field-alert",
                                    class: { "d-block": item.operatorError },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: {
                                        icon: ["far", "circle-exclamation"],
                                      },
                                    }),
                                    _vm._v(
                                      "  " +
                                        _vm._s(
                                          _vm.$t("dataview.error.operator")
                                        ) +
                                        " "
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("FilterInput", {
                              attrs: {
                                disabled: _vm.disabled,
                                schema: _vm.schema,
                                macros: _vm.macros,
                              },
                              model: {
                                value: _vm.query.children[itemIndex],
                                callback: function ($$v) {
                                  _vm.$set(_vm.query.children, itemIndex, $$v)
                                },
                                expression: "query.children[itemIndex]",
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "d-flex" },
                              [
                                _vm.allowEditing
                                  ? _c(
                                      "b-form-checkbox",
                                      {
                                        staticClass: "allow-editing ml-1",
                                        attrs: { disabled: _vm.disabled },
                                        on: {
                                          change: function ($event) {
                                            return _vm.allowEditingChange(item)
                                          },
                                        },
                                        model: {
                                          value: item.allowEditing,
                                          callback: function ($$v) {
                                            _vm.$set(item, "allowEditing", $$v)
                                          },
                                          expression: "item.allowEditing",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("dataview.allow_edit")
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "b-btn",
                                  {
                                    staticClass: "edit-name-button",
                                    attrs: { disabled: !item.allowEditing },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onEditDisplayName(item)
                                      },
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      staticClass: "ml-1",
                                      attrs: {
                                        id: `EDIT_BUTTON_${_vm.id}_${itemIndex}`,
                                        icon: ["far", "pen-to-square"],
                                      },
                                    }),
                                    _c("b-popover", {
                                      attrs: {
                                        target: `EDIT_BUTTON_${_vm.id}_${itemIndex}`,
                                        placement: "top",
                                        triggers: "hover",
                                        content: _vm.$t(
                                          "dataview.edit_display_name"
                                        ),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-form-invalid-feedback",
                          {
                            staticClass: "alert-danger form-field-alert",
                            class: { "d-block": item.valueError },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["far", "circle-exclamation"] },
                            }),
                            _vm._v(
                              "  " +
                                _vm._s(_vm.$t("dataview.error.value")) +
                                " "
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-form-invalid-feedback",
                          {
                            staticClass: "alert-danger form-field-alert",
                            class: { "d-block": item.regexError },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["far", "circle-exclamation"] },
                            }),
                            _vm._v("  " + _vm._s(item.regexError) + " "),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                item.type === "group"
                  ? _c("FilterComponent", {
                      attrs: {
                        disabled: _vm.disabled,
                        level: _vm.level + 1,
                        root: _vm.root,
                        userId: _vm.userId,
                        index: itemIndex,
                        schema: _vm.schema,
                        predicate: _vm.predicate,
                        macros: _vm.macros,
                      },
                      on: { remove: _vm.onRemove },
                      model: {
                        value: _vm.query.children[itemIndex],
                        callback: function ($$v) {
                          _vm.$set(_vm.query.children, itemIndex, $$v)
                        },
                        expression: "query.children[itemIndex]",
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c("FieldSelectModal", {
        attrs: {
          show: _vm.showFieldSelect,
          root: _vm.root,
          userId: _vm.userId,
          field: _vm.itemEdit !== null ? _vm.itemEdit.field : null,
          agFunc: _vm.itemEdit !== null ? _vm.itemEdit.agFunc : null,
          schema: _vm.schema,
          macros: _vm.macros,
          isFilter: true,
        },
        on: {
          "update:show": function ($event) {
            _vm.showFieldSelect = $event
          },
          success: _vm.fieldSelectOk,
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("dataview.confirmation.allow_editing"),
            size: "md",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": "anti-shift",
          },
          on: { ok: _vm.editDisplayNameOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  [
                    !_vm.disabled
                      ? _c(
                          "b-button",
                          {
                            attrs: {
                              disabled: _vm.editDisplayNameVal === null,
                              size: "sm",
                              variant: "success",
                            },
                            on: { click: _vm.editDisplayNameOk },
                          },
                          [_vm._v(_vm._s(_vm.$t("button.ok")))]
                        )
                      : _vm._e(),
                  ],
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.editDisplayName,
            callback: function ($$v) {
              _vm.editDisplayName = $$v
            },
            expression: "editDisplayName",
          },
        },
        [
          _c("label", { staticClass: "d-block mr-1" }, [
            _vm._v(_vm._s(_vm.$t(`dataview.chart.field_name`))),
          ]),
          _c("b-form-input", {
            staticClass: "d-block w-auto mt-1",
            attrs: { disabled: "" },
            model: {
              value: _vm.editDisplayNameField,
              callback: function ($$v) {
                _vm.editDisplayNameField = $$v
              },
              expression: "editDisplayNameField",
            },
          }),
          _c(
            "b-form-group",
            {
              staticClass: "mt-2",
              attrs: {
                label: _vm.$t("dataview.chart.display_name"),
                "label-for": "displayname",
              },
            },
            [
              _c(
                "b-input-group",
                [
                  _c("b-form-input", {
                    attrs: { type: "text", readonly: _vm.disabled },
                    model: {
                      value: _vm.editDisplayNameVal,
                      callback: function ($$v) {
                        _vm.editDisplayNameVal = $$v
                      },
                      expression: "editDisplayNameVal",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }