<template>
  <div>
    <b-modal v-model="modalShow" size="sm" :title="title" footer-class="footerClass"
      @hide="hide"
      content-class="shadow" no-close-on-backdrop
    >
      <div @click="closePicker">
        
        <div class="color-swatches">
          <div v-bind:key="itemIndex" v-for="(item, itemIndex) in swatches"
            :id="`COLOR_SWATCH_${itemIndex}`"
            @click="handleInput(item)"
            class="color-style color-swatch"
            :style="`background-color: ${item}`">
            <b-popover
               :target="`COLOR_SWATCH_${itemIndex}`"
               placement="top"
               triggers="hover"
               no-fade
               :delay="0"
               :content="`${$t('field.color')}: ${item}`">
             </b-popover>
           </div>
           <div class="d-flex w-100 mt-1 current-container">
            <div class="picker-btn-container">
              <div id="COLOR_CURRENT"
                @click="togglePicker"
                :class="colorData === null || colorData === '' ? 'color-none picker-btn' : 'picker-btn'"
                :style="colorData === null || colorData === '' ? '' : `background-color: ${colorData}`">
               <b-popover
                  target="COLOR_CURRENT"
                  placement="top"
                  triggers="hover"
                  :content="`${$t('field.color')}: ${colorData ? colorData : $t('none')}`">
                </b-popover>
              </div>
            </div>
            <b-btn class="clear-button" @click="clearColor">{{ $t('button.clear') }}</b-btn>
          </div>
        </div>
      
        <div v-show="showPicker" class="picker" tabindex="-1" ref="picker" @blur="blurPicker">
          <Chrome :value="{hex: colorData}" @input="updateColor" :disableAlpha="true"/>
        </div>
      </div>
          
      <template v-slot:modal-footer="{}">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <template>
          <b-button size="sm" variant="success" @click="Ok(true)">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="hide">{{ $t('SELECT' === mode? 'button.close':'button.cancel') }}</b-button>
        
      </template>
    </b-modal>
    
  </div>
</template>

<script>

import { Chrome } from 'vue-color'
import { colorProfileService } from '@/services';

export default {
  name: 'ColorModal',
  components: {
    'Chrome': Chrome
  },
  props: {
    title:        { type: String,   default: function() { return this.$t('task.edit_color'); } },
    show:         { type: Boolean, required: true },
    mode:         { type: String, default: 'BOTH' }, //Possible value: ['SELECT', 'MANAGE', 'BOTH'],
    color:        { type: String, default: null },
    update:       { type: String, default: null }
  },
  data() {
    return {
      uuId: null,
      modalShow: false,
      colorData: null,
      swatches: [
        '#20a8d8',
        '#6610f2',
        '#6f42c1',
        '#e83e8c',
        '#f86c6b',
        '#f8cb00',
        '#ffc107',
        '#4dbd74',
        '#20c997',
        '#17a2b8',
        '#712302',
        '#757575',
        '#C77305',
        '#63c2de',
        '#E77615',
        '#005581',
        '#29B6F6',
        '#E0E0E0'
      ],
      showPicker: false
    }
  },
  created() {
    this.colorData = this.color;
    this.modalShow = this.show;
  },
  watch: {
    show(newValue) {
      if(newValue != this.modalShow) {
        this.colorData = this.color;
        this.modalShow = newValue;
      }
    },
    update(newValue) {
      if (newValue) {
        if (!this.swatches.includes(newValue)) {
          this.swatches.unshift(newValue);
          this.swatches.pop(); // add the new color and remove the last
          colorProfileService.update([{ uuId: this.uuId, swatches: this.swatches }], localStorage.companyId);
        }
      }
    }
  },
  mounted() {
    const self = this;
    this.$store.dispatch('data/colorProfileList', localStorage.companyId).then((response) => {
      if (response && response.length === 0) {
        colorProfileService.create([{ swatches: self.swatches }], localStorage.companyId).then((create_response) => {
          const feedbackList = create_response.data.feedbackList;
          if (Array.isArray(feedbackList) && 
                feedbackList.length > 0 && 
                feedbackList[0].uuId != null) {
            self.uuId = feedbackList[0].uuId;
          }
        });
      }
      else {
        self.uuId = response[0].uuId;
        self.swatches = response[0].swatches;
      }
    });
  },
  methods: {
    keyDown(e) {
      if (e.keyCode === 13) {
        this.showPicker = false;
      }
    },
    Ok() {
      this.updatedColor = this.colorData;
      this.$emit('update:show', false);
      this.$emit('changed', this.colorData);
    },
    hide() {
      this.$emit('update:show', false);
      this.$emit('cancel');
    },
    handleInput (value) {
      this.colorData = value;
    },
    updateColor(value) {
      this.handleInput(value.hex);
    },
    clearColor(value) {
      this.handleInput(null);
      this.showSwatches = false;
    },
    closePicker(e) {
      if (e.target.className === 'color-swatches') {
        this.showPicker = false;
        
        // clean up the listener we added when showing the picker
        const elem = document.querySelector('.vc-editable-input input');
        if (elem) {
          elem.removeEventListener('keydown', this.keyDown);
          elem.removeEventListener('blur', this.onInputBlur);
        }
      }
    },
    onInputBlur(e) {
      this.$refs.picker.focus();
    },
    blurPicker(e) {
      // if the blur was because of outside focus
      // currentTarget is the parent element, relatedTarget is the clicked element
      if (!e.currentTarget.contains(event.relatedTarget)) {
        this.showPicker = false;
      }
    },
    togglePicker(e) {
      e.stopPropagation();
      const self = this;
      this.showPicker = !this.showPicker; 
      if (this.showPicker) {
        setTimeout(() => {
          this.$refs.picker.focus();
          const elem = document.querySelector('.vc-editable-input input');
          if (elem) {
            elem.addEventListener('keydown', self.keyDown);
            elem.addEventListener('blur', self.onInputBlur);
          }
        }, 10);
      }
    }

  }
}
</script>

<style lang="scss">

  .color-swatches {
    display: flex;
    flex-wrap: wrap;
  }
  
  .color-swatch {
    height: 35px;
    width: 35px;
    display: flex;
    vertical-align: middle;
    border-radius: 3px;
    margin: 4px;
    cursor: pointer;
  }
  
  .color-none {
    background-image: linear-gradient(45deg, #aaaaaa 26%, transparent 25%), linear-gradient(-45deg, #aaaaaa 26%, transparent 25%), linear-gradient(45deg, transparent 75%, #aaaaaa 75%), linear-gradient(-45deg, transparent 75%, #aaaaaa 75%);
    background-size: 10px 10px;
    background-position: 0 0, 0 5px, 5px -5px, -5px 0px;
  }
  
  .selection-icon {
    font-size: 18px;
    margin-top: 1px;
    color: var(--gray-500);
  }
  
  .select-menu {
    padding: 0 2px;
  }
  
  .current-container {
    margin-left: 3px;
    margin-right: 12px;
  }
  
  .picker-btn-container {
    flex-grow: 2;
    border-radius: 3px;
    margin-right: 4px;
    border: 1px solid var(--border-medium);
  }
  
  .picker-btn {
    flex-grow: 2;
    border-radius: 3px;
    margin: 2px;
    cursor: pointer;
    height: 29px;
  }
  
  .picker {
    position: absolute;
    z-index: 1;
    top: 0;
  }
  
  .vc-chrome {
    font-family: Roboto !important;
  }
  
  .background-click {
    position: absolute;
    width: 100%;
    height: 100%;
  }
</style>